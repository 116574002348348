<template>
  <div class="preview-actions">
    <div class="d-flex justify-content-end flex-wrap">
      <ClNavButton
        :loading="loading"
        :label="$t('buttons.goBack')"
        @handler="goBack"
      />
      <CButton
        size="sm"
        class="header-icon-buttons mb-2 ml-3"
        @click="handleReload"
      >
        <CIcon name="cil-reload"/>
      </CButton>
    </div>
  </div>
</template>

<script>
import ClNavButton from '@/shared/components/formComponents/ClNavButton';

export default {
  name: 'ActionsHeader',
  components: {
    ClNavButton
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    goBack() {
      this.$emit('goBack');
    },
    handleReload() {
      this.$emit('reload');
    },
  }
}
</script>

<style lang="scss">
.preview-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 12px;
  .action-create-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 160px;
  }
}
</style>