<template>
  <div class="competitions-leaderboard content">
    <div class="competitions-leaderboard-header">
      <ActionsHeader @goBack="goBack" @reload="handleReload"/>
    </div>
    <div class="competitions-leaderboard--content">
      <CCard class="zq--wizard-card">
        <CCardBody>
          <h4 class="mb-4">{{ contestName }} {{ texts.leaderboardDetail.leaderboard }}</h4>
          <CDataTable
            id="leaderboardTableId"
            class="zq--table"
            :items="leaderboardData"
            :fields="tableFields"
            :searchableAllFields="searchableAllFields"
            :column-filter="columnFilter"
            :items-per-page="itemsPerPage"
            :sorter="{ external: true }"
            :responsive="true"
            :border="isDefaultTheme"
            :hover="isDefaultTheme"
            :striped="isDefaultTheme"
            :loading="loading"
            @pagination-change="itemsPerPageSelect"
            @page-change="paginationChange"
            @update:sorter-value="handleSortBy"
            :sorter-value.sync="sorterValue"
          >
            <template #loading>
              <TableLoader />
            </template>
          </CDataTable>
          <TableFooter
            :page="page"
            @updatePagenation="paginationChange"
            :pages="getPages"
            :total="totalRecords"
            :itemsPerPage="itemsPerPage"
            :disabled="!ready"
            @updateItemPerPage="itemsPerPageSelect"
          />
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import ActionsHeader from './components/ActionsHeader';
import {competitionsTexts} from '@/config/pageTexts/competitions.json';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import TableLoader from '@/components/table/Loader';
import TableFooter from '@/components/table/Footer';
import {mapActions, mapGetters} from 'vuex';
import {pageConfig} from "@/config";

export default {
  name: 'ContestLeaderboard',
  components: {
    ActionsHeader,
    TableLoader,
    TableFooter,
  },
  data() {
    return {
      ready: false,
      texts: {
        ...competitionsTexts
      },
      rounds: '',
      leaderboardData: [],
      tableFields: [
        'memberId',
        'memberRefId',
        'name',
        'points',
        'rank',
      ],
      searchableAllFields: [],
      columnFilter: false,
      itemsPerPage: pageConfig.itemsPerPage,
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
    }
  },
  computed: {
    ...mapGetters('competitions', ['competition']),
    ...mapGetters('contests', ['contest', 'loading', 'pages', 'totalRecords']),
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default';
    },
    getPages() {
      return isNaN(this.pages) ? 1 : this.pages;
    },
    contestName() {
      return this.contest.name ?? '';
    },
    sorterValue: {
      get() {
        const sorter = (this.sortBy || [])[0];
        const key = sorter ? sorter.order.toLowerCase() : undefined;
        if (!key) return undefined;

        return {
          column: sorter.queryField,
          desc: key === 'desc',
          asc: key === 'asc',
        };
      },
      set(val) {
        this.sortBy[0] = {
          queryField: val.column,
          order: val.asc ? 'Asc' : 'Desc',
        };
      },
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('contests', ['handleGetContests_item', 'handleGetContestsLeaderboardById']),
    ...mapActions('competitions', ['handleGetCompetitions']),
    async initialize() {
      let competitionName = this.competition.name;
      let contest = this.contest;

      if (!competitionName) {
        const competitions = await this.handleGetCompetitions({idArray: [this.$router.currentRoute.params.id]});
        competitionName = competitions[0].name;
      }

      if (!Object.keys(contest).length) {
        const contests = await this.handleGetContests_item([this.$router.currentRoute.params.contestId], 1, 0);
        contest = contests[0];
      }

      routerBreadcrumbs({name: 'LeaderboardDetail'}, {
        competitionId: contest.competitionId,
        competitionName: competitionName,
        name: contest.name,
        spaceName: contest.spaceName,
      });

      this.leaderboardData = await this.handleGetContestsLeaderboardById({
        id: this.$router.currentRoute.params.contestId,
        limit: this.itemsPerPage,
        skip: (this.page - 1) * this.itemsPerPage,
      });

      this.ready = true;
    },
    async queryDb() {
      this.leaderboardData = await this.handleGetContestsLeaderboardById({
        id: this.$router.currentRoute.params.contestId,
        limit: this.itemsPerPage,
        skip: (this.page - 1) * this.itemsPerPage,
      });
    },
    goBack() {
      this.$router.push({
        name: 'PreviewCompetition',
        params: {
          id: this.$router.currentRoute.params.id,
        },
        query: {
          contestId: this.$router.currentRoute.params.contestId,
        }
      })
    },
    handleReload() {
      this.page = 1;
      this.queryDb();
    },
    async itemsPerPageSelect(val) {
      this.itemsPerPage = val;
      this.page = 1;
      await this.queryDb();
    },
    async paginationChange(val) {
      this.page = val;
      await this.navQueryDb();
    },
    async handleSortBy(e) {
      this.sortBy = [
        {
          queryField: e.column,
          order: e.asc ? 'Asc' : 'Desc',
        },
      ];
      this.page = 1;
      await this.queryDB();
    },
  },
}
</script>

<style lang="scss">
.competitions-leaderboard {
  .competitions-leaderboard-header {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap-reverse;;
  }
  .competitions-leaderboard--content {
    .zq--wizard-card {
      border-top: none;
      .card-body {
        border-radius: 12px;
        border-top: none;
      }
    }
    .zq--wizard-card.leader-board {
      z-index: 10;
    }
  }
}
</style>